// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Progress,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import React from "react";
// Variables
import {
  barChartData,
  barChartOptions,
  lineChartData,
  lineChartOptions,
} from "variables/charts";
import { pageVisits, socialTraffic } from "variables/general";



import raw from '../../tms_files/smdr_doj remot_20.1.2.csv';
import csvToJson from 'csvtojson';
import moment from 'moment';
const csvFilePath = '../../tms_files/smdr_doj remot_20.1.2.csv';

function getDateFromHours(time) {
  time = time.split(':');
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), ...time);
  asdasd;
}

console.log(getDateFromHours('01:12:33'));


const parseCsv = csv => {
  let lines = csv.split("\n");
  const header = lines.shift().split(";")
  lines.shift(); // get rid of definitions
  return lines.map(line => {
    const bits = line.split(";")
    let obj = {};
    header.forEach((h, i) => obj[h] = bits[i]); // or use reduce here
    return obj;
  })
};

function json2array(json){
  var result = [];
  var keys = Object.keys(json);
  keys.forEach(function(key){
      result.push(json[key]);
  });
  return result;
}

let totalCalls = 10000;
let dataRecord = [];
let callStats = [];
let callStatsArr = [];

let totDuration = "";

let droppedCalls=0;
let internalCalls=0;
let outgoinglCalls=0;

fetch(raw)
  .then(r => r.text())
  .then(text => {
    console.log('text decoded:', text);
   // let userObj = JSON.parse(text);
    //console.log('test2',userObj);
    console.log('test',JSON.parse(JSON.stringify(text)));
    const titles = parseCsv(text)

    console.log("Raw Records-: ", titles)
   
    const obj = Object.entries(titles).map(([key, value]) => ({key, value}))

    function toHoursAndMinutes(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
    }
    function padToTwoDigits(num) {
      return num.toString().padStart(2, "0");
    }
    console.log(toHoursAndMinutes(100)); // 01:40
    console.log(toHoursAndMinutes(60)); // 01:00
    console.log(toHoursAndMinutes(130)); // 02:10
    
    var testmin = toHoursAndMinutes(6020);
    var newtot = 
    console.log(testmin.split(":"));
    
   
    dataRecord = json2array(titles);

    console.log("Arr : ",dataRecord);
    var split_hr = 0;
    var split_min = 0;
    var split_sec = 0;
    var sumHr = 0;
    var sumMin = 0;
    var sumSec= 0;
   

    let splitTotalsec="";

    let d = new Date();
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    var todayDate = da+"-"+mo;

    for (const dataRecords of dataRecord) {
      const durationSplit = dataRecords.duration.split(":");

      console.log("Record :",dataRecords.date)


      //date;time;duration;calling 
      callStatsArr = [
        {
          pageName: dataRecords.date +" "+dataRecords.time,
          visitors: dataRecords.calling_ext,
          uniqueUsers: dataRecords.duration,
          bounceRate: dataRecords.caller_phone_string,
        },];
        callStats.push(callStatsArr);

      ///Get Today's Date Data
      if(dataRecords.date == todayDate ){


      console.log("Date :",dataRecords.date)
      //console.log("Duration :",dataRecords.duration)
      //console.log("Duration Split :",durationSplit)

      if(parseInt(durationSplit[0]) ==0 &&  parseInt(durationSplit[1]) ==0 && parseInt(durationSplit[2])==0){

        droppedCalls = droppedCalls+1;
      }

      if(dataRecords.flag == "I"){
        internalCalls++;
      }
      if(dataRecords.flag == "A"){
        outgoinglCalls++;
      }

      if(durationSplit[0] == ""){
        split_hr=0;
      }
      if(durationSplit[1] == ""){
        split_min=0;
      }
      if(durationSplit[2] == ""){
        split_sec=0;
      }


      split_hr = parseInt(durationSplit[0]);
      split_min = parseInt(durationSplit[1]);
      split_sec = parseInt(durationSplit[2]);

      split_hr = split_hr || 0
      split_min = split_min || 0
      split_sec = split_sec || 0



      sumHr += split_hr;
      sumMin += split_min;
      sumSec += split_sec;


      //console.log("Hour", split_hr);
      //console.log("Min", split_mn);
      //console.log("Sec", split_sec);
    }else{
      totDuration = 0;

    }


      
    }


    //Sec to Min
    let secToSplit = toHoursAndMinutes(sumSec).split(":");
    let min_plus_sec = parseInt(secToSplit[0])+sumMin;

    //Min to hours
    let minToSplit = toHoursAndMinutes(sumMin).split(":");
    let hour_plus_min = parseInt(minToSplit[0])+sumHr;

    console.log("Totla Hrs ",sumHr);
    console.log("Totla Min ",sumMin);
    console.log("Totla Sec ",sumSec);

    console.log("Sec Split :",secToSplit);
    console.log("MIn Split :",minToSplit);

    

    console.log("Total Hour+min :",hour_plus_min);
    console.log("Total Min+sec :",parseInt(minToSplit[1]));
    console.log("Total Sec :",parseInt(secToSplit[1]));


    console.log("Ttot dur:",hour_plus_min + ":" + parseInt(minToSplit[1]) + ":" + parseInt(secToSplit[1]));

    totDuration =hour_plus_min + ":" + parseInt(minToSplit[1]) + ":" + parseInt(secToSplit[1]);

    console.log("Internal Calls ",internalCalls);
   // alert(sumHr);

   console.log("Testing Table Data", callStats);
   console.log("Testing2 Table Data2", pageVisits);
   
  });

 


export default function Dashboard() {
  // Chakra Color Mode
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const tableRowColor = useColorModeValue("#F7FAFC", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textTableColor = useColorModeValue("gray.500", "white");

  const { colorMode } = useColorMode();



  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Today's Calls Duration 
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                    {totDuration}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={iconBlue}>
                <WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Since last month
            </Text>
          </Flex>
        </Card>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Outgoing Calls
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                    {outgoinglCalls}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={iconBlue}>
                <GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +5.2%{" "}
              </Text>
              Since last month
            </Text>
          </Flex>
        </Card>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Dropped Calls
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                    {droppedCalls}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={iconBlue}>
                <DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='red.500' fontWeight='bold'>
                -2.82%{" "}
              </Text>
              Since last month
            </Text>
          </Flex>
        </Card>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Internal Calls
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                    {internalCalls}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={iconBlue}>
                <CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +8.12%{" "}
              </Text>
              Since last month
            </Text>
          </Flex>
        </Card>
      </SimpleGrid>
      <Grid
        templateColumns={{ sm: "1fr", lg: "2fr 1fr" }}
        templateRows={{ lg: "repeat(2, auto)" }}
        gap='20px'>
        <Card
          bg={
            colorMode === "dark"
              ? "navy.800"
              : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
          }
          p='0px'
          maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <Text color='#fff' fontSize='lg' fontWeight='bold' mb='6px'>
              Total Calls
            </Text>
            <Text color='#fff' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                (+5) more{" "}
              </Text>
              in January
            </Text>
          </Flex>
          <Box minH='300px'>
            <LineChart
              chartData={lineChartData}
              chartOptions={lineChartOptions}
            />
          </Box>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
            <Text color='gray.400' fontSize='sm' fontWeight='bold' mb='6px'>
              PERFORMANCE
            </Text>
            <Text color={textColor} fontSize='lg' fontWeight='bold'>
              Total Calls
            </Text>
          </Flex>
          <Box minH='300px'>
            <BarChart chartData={barChartData} chartOptions={barChartOptions} />
          </Box>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Call Stats
              </Text>
              <Button variant='primary' maxH='30px'>
                SEE ALL
              </Button>
            </Flex>
            <Box overflow={{ sm: "scroll", lg: "hidden" }}>
              <Table>
                <Thead>
                  <Tr bg={tableRowColor}>
                    <Th color='gray.400' borderColor={borderColor}>
                      Date
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Calling Number
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Duration
                    </Th>
                    <Th color='gray.400' borderColor={borderColor}>
                      Called Number
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  
                  {callStats.slice(0, 10).map((el, index, arr) => {
                    
                    //for (const iterator of index) {
                      console.log("MAP: ", el[0].pageName)
                    //}
                    let counter = 0;
                    return (
                     
                      <Tr key={index}>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          fontWeight='bold'
                          borderColor={borderColor}
                          border={index === arr.length - 1 ? "none" : null}>
                          {el[0].pageName}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el[0].visitors}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el[0].uniqueUsers}
                        </Td>
                        <Td
                          color={textTableColor}
                          fontSize='sm'
                          border={index === arr.length - 1 ? "none" : null}
                          borderColor={borderColor}>
                          {el[0].bounceRate}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </Flex>
        </Card>
        <Card p='0px' maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='22px'>
              <Text fontSize='lg' color={textColor} fontWeight='bold'>
                Trunk Stats
              </Text>
              <Button variant='primary' maxH='30px'>
                SEE ALL
              </Button>
            </Flex>
          </Flex>
          <Box overflow={{ sm: "scroll", lg: "hidden" }}>
            <Table>
              <Thead>
                <Tr bg={tableRowColor}>
                  <Th color='gray.400' borderColor={borderColor}>
                    Provider
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}>
                    Calls
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {socialTraffic.map((el, index, arr) => {
                  return (
                    <Tr key={index}>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        fontWeight='bold'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.referral}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.visitors}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        <Flex align='center'>
                          <Text
                            color={textTableColor}
                            fontWeight='bold'
                            fontSize='sm'
                            me='12px'>{`${el.percentage}%`}</Text>
                          <Progress
                            size='xs'
                            colorScheme={el.color}
                            value={el.percentage}
                            minW='120px'
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </Card>
      </Grid>
    </Flex>
  );
}
