// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import TablesTableRow from "components/Tables/TablesTableRow";
import React from "react";
import { tablesProjectData, tablesTableData } from "variables/general";

import raw from '../../tms_files/smdr_doj remot_20.1.2.csv';
import { addDays } from 'date-fns';
import { useState } from 'react';

//Date Picker
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';



import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Icon,
  Link,
  Switch,
  Stack, 
  VStack,
  Spacer,
  Grid, 
  GridItem,
  colorScheme,
} from "@chakra-ui/react";



const parseCsv = csv => {
  let lines = csv.split("\n");
  const header = lines.shift().split(";")
  lines.shift(); // get rid of definitions
  return lines.map(line => {
    const bits = line.split(";")
    let obj = {};
    header.forEach((h, i) => obj[h] = bits[i]); // or use reduce here
    return obj;
  })
};

function json2array(json){
  var result = [];
  var keys = Object.keys(json);
  keys.forEach(function(key){
      result.push(json[key]);
  });
  return result;
}


let dataRecord = [];
let callStats = [];
let callStatsArr = [];

let totDuration = "";

let droppedCalls=0;
let internalCalls=0;
let outgoinglCalls=0;



fetch(raw)
  .then(r => r.text())
  .then(text => {

    const titles = parseCsv(text)
    const obj = Object.entries(titles).map(([key, value]) => ({key, value}))

    function toHoursAndMinutes(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
    }
    function padToTwoDigits(num) {
      return num.toString().padStart(2, "0");
    }
 
    
    var testmin = toHoursAndMinutes(6020);
    var newtot = 
   
    dataRecord = json2array(titles);

    var split_hr = 0;
    var split_min = 0;
    var split_sec = 0;
    var sumHr = 0;
    var sumMin = 0;
    var sumSec= 0;
   

    let splitTotalsec="";

    let d = new Date();
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    var todayDate = da+"-"+mo;

    for (const dataRecords of dataRecord) {
      const durationSplit = dataRecords.duration.split(":");

      console.log("Record :",dataRecords.date)


      //date;time;duration;calling 
      callStatsArr = [
        {
          date: dataRecords.date +" "+dataRecords.time,
          caller: dataRecords.calling_ext,
          duration: dataRecords.duration,
          boucalledPhone: dataRecords.caller_phone_string,
        },];
        callStats.push(callStatsArr);

      ///Get Today's Date Data
      if(dataRecords.date == todayDate ){



      if(parseInt(durationSplit[0]) ==0 &&  parseInt(durationSplit[1]) ==0 && parseInt(durationSplit[2])==0){

        droppedCalls = droppedCalls+1;
      }

      if(dataRecords.flag == "I"){
        internalCalls++;
      }
      if(dataRecords.flag == "A"){
        outgoinglCalls++;
      }

      if(durationSplit[0] == ""){
        split_hr=0;
      }
      if(durationSplit[1] == ""){
        split_min=0;
      }
      if(durationSplit[2] == ""){
        split_sec=0;
      }


      split_hr = parseInt(durationSplit[0]);
      split_min = parseInt(durationSplit[1]);
      split_sec = parseInt(durationSplit[2]);

      split_hr = split_hr || 0
      split_min = split_min || 0
      split_sec = split_sec || 0

      sumHr += split_hr;
      sumMin += split_min;
      sumSec += split_sec;

    }else{
      totDuration = 0;

    }

    }

    //Sec to Min
    let secToSplit = toHoursAndMinutes(sumSec).split(":");
    let min_plus_sec = parseInt(secToSplit[0])+sumMin;

    //Min to hours
    let minToSplit = toHoursAndMinutes(sumMin).split(":");
    let hour_plus_min = parseInt(minToSplit[0])+sumHr;

    totDuration =hour_plus_min + ":" + parseInt(minToSplit[1]) + ":" + parseInt(secToSplit[1]);
   
  });




function Tables() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
   <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card my="22px" overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
            Call Stats
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color={textColor}>
          <Thead>
              <Tr my=".8rem" pl="0px">
                <Th pl="0px" color="gray.400" borderColor={borderColor}>
                Date
                </Th>
                <Th color="gray.400" borderColor={borderColor}>Duration</Th>
                <Th color="gray.400" borderColor={borderColor}>Caller Number</Th>
                <Th color="gray.400" borderColor={borderColor}>Progress</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              
              {callStats.map((row, index, arr) => {
                console.log("roow: ",row[0]);
                return (
                  <TablesProjectRow
                    name={row[0].date}
                    
                    status={row[0].caller}
                    budget={row[0].duration}
                    progression={Math.floor(Math.random() * 100) + 1}
                    isLast={index === arr.length - 1 ? true : false}
                    key={index}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      
    </Flex>
  );
}

export default Tables;
